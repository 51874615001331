<template>
  <div class="my-3 lg:my-5 xl:my-6 space-y-1">
    <!-- <GlobalSkeleton :loading="loading" class="w-6/10 h-6 mb-3" /> -->
    <h4 class="text-bw-09/50 text-10px lg:text-sm font-semibold uppercase mb-3 xl:mb-3">{{ data?.title }}</h4>
    <wrapper-dropdown
        v-if="dataMerge && dataMerge.length > 0"
        v-for="item in dataMerge"
        :key="item.id"
        :item="item"
        :category_title="data?.title"
        :loading="loading"
    />
  </div>
  <div v-if="indexCategory < lengthCategory - 1" class="w-full h-1px bg-bw-08"></div>
</template>

<script setup lang="ts">
import WrapperDropdown from './__WrapperDropdown.vue'

const props = defineProps({
  data: {
    type: Object
  },
  heading: {
    type: String
  },
  listContent: {
    type: Array
  },
  lengthCategory: {
    type: Number
  },
  indexCategory: {
    type: Number
  },
  loading: {
    type: Boolean
  }
})
const dataMerge = computed(() => {
  if (props?.data?.childs?.length > 0 || props?.data?.faqs?.length) {
    return [...props?.data?.childs, ...props?.data?.faqs]
  } else {
    return []
  }
})
</script>

<style></style>
