<template>
  <div class="w-full cursor-pointer wrapper-item-faq" @click="isOpen = !isOpen ? item?.faqs.length > 0 : false">
    <!-- <GlobalSkeleton :loading="loading" class="w-full h-10 mb-1 ml-2 mb-3" /> -->

    <div
        v-if="item?.faqs"
        :class="[isOpen ? ' bg-primary/10 ' : ' bg-white']"
        class="flex justify-between px-2 lg:px-5 py-2 lg:py-3 duration-200 rounded xl:rounded-xl hover:bg-primary/15"
    >
      <p class="text-sm lg:text-base text-bw-11 font-semibold line-clamp-2">{{ item?.title }}</p>
      <span
          v-if="item?.faqs.length > 0"
          :class="isOpen ? 'rotate-90' : 'rotate-0'"
          class="flex-none transform i-ic:sharp-chevron-right text-lg text-bw-11/80 duration-200"
      ></span>
    </div>
    <div
        v-if="item?.faqs && item?.faqs.length > 0"
        class="item-parent"
        :class="isOpen ? 'activeList mt-3 xl:mt-5' : ''"
    >
      <div class="space-y-2 pl-7 pr-2 pt-4 pb-2">
        <nuxt-link
            v-for="faq in item?.faqs"
            :to="faq.url"
            :id="`item-faq-` + faq.id"
            class="block text-black/60"
        >
          {{ faq.title }}
        </nuxt-link>
      </div>
    </div>
  </div>

  <nuxt-link
      v-if="!item?.childs && item?.title"
      @click="trackingNews(category_title, item)"
      :to="item?.url"
      :id="`item-faq-` + item?.id"
      :class="route.params.id == item?.id ? 'bg-primary/15' : ''"
      class="flex justify-between px-2 lg:px-3 xl:px-5 py-2 xl:py-3 duration-200 rounded-8px hover:bg-primary/15"
  >
    <p class="text-bw-11 font-semibold line-clamp-2 text-13px xl:text-base">
      {{ item?.title }}
    </p>
  </nuxt-link>
</template>

<script lang="ts" setup>
import {FAQS_EVENT_ID, useEventTracking} from "../../../../composables/ackee/event";

const props: any = defineProps({
  item: {
    type: Object
  },
  category_title: {
    type: String
  },
  loading: {
    type: Boolean
  }
})
const route = useRoute()
const isOpen = ref<boolean>(false)
const activeById = (idCurrent: any) => {
  if (idCurrent?.id) {
    isOpen.value = true
  }
}
onMounted(() => {
  let idCurrent = null
  if (props.item.faqs && props.item.faqs.length > 0) {
    idCurrent = props.item?.faqs.find((faq: any) => +faq.id === +route.params.id)
    activeById(idCurrent)
  }
})

const trackingNews = (category_title: any, dataItem: any) => {
  useEventTracking(FAQS_EVENT_ID, `${category_title} - ${dataItem?.translations?.title}`)
}
</script>
<style scoped>
.wrapper-item-faq .router-link-active {
  @apply text-primary;
}
</style>
