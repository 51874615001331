<template>
  <section class="pr-2">
    <div
        class="wrapperSidebar w-full h-full overflow-auto lg:max-h-75vh xl:max-h-85vh custom-scrollbar pr-4 pl-4 xl:pl-0 xl:pr-6 pt-3"
    >
      <div class="hidden lg:block searchFAQ xl:pl-1.5">
        <searchFAQ @searchText="search"/>
      </div>
      <wrapper-list-dropdown
          v-if="category && category.length > 0"
          v-for="(item, index) in category"
          :data="item"
          :loading="loading"
          :indexCategory="index"
          :lengthCategory="+category.length"
      />
    </div>
    <!-- Search input -->
    <!-- List Dropdown -->
  </section>
</template>

<script setup lang="ts">
import WrapperListDropdown from './__WrapperListDropdown.vue'
import searchFAQ from './Search.vue'

defineProps({
  category: {
    type: Array
  },
  loading: {type: Boolean}
})
const emits = defineEmits(['searchText'])
const search = (textSeach: any) => {
  emits('searchText', textSeach)
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.wrapperSidebar:hover {
  &::-webkit-scrollbar-thumb {
    background: #d0d0d0;
  }

  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: #f6f6f6; /* Optional: just make scrollbar invisible */
  }
}
</style>
