<template>
  <div
      @click.self="$emit('closePopup')"
      class="fixed inset-0 bg-black/80 overflow-auto py-5 lg:py-10 z-1000 items-center flex"
  >
    <div @click.self="$emit('closePopup')" class="container flex flex-col items-center justify-center">
      <div class="relative bg-white h-auto w-full max-w-lg rounded-20px py-5 lg:py-10">
                <span
                    class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                    @click="$emit('closePopup')"
                ></span>
        <div class="px-5 lg:px-10 py-5 lg:py-8 text-center">
          <h5
              class="text-2xl sm:text-30px font-[550] leading-7 sm:leading-44px text-center text-black mb-3 max-w-92 mx-auto space-x-1.5"
          >
            <span>{{ $t('THIS_ARTICLE') }}</span>
            <span class="text-primary">
                            {{ $t('IS_USEFUL') }}
                        </span>
            <span class="text-black">
                            {{ $t('TO_YOU') }}
                        </span>
          </h5>
          <p class="text-sm lg:text-base text-black/60">
            {{ $t('YOUR_REVIEW_WILL_HELP_US_IMPROVE_THE_QUALITY_OF_FUTURE_ARTICLES') }}
          </p>
        </div>
        <form action="" @submit.prevent="submitFeedbackJs" class="form-submit space-y-4">
          <div class="px-5 lg:px-10 space-y-2">
            <div class="space-y-1">
              <label
                  for="name_feedback_faq"
                  class="text-black/25 text-10px md:text-13px block pb-2 uppercase"
              >
                {{ $t('ENTER_YOUR_FIRST_AND_LAST_NAME_FAQ') }}
              </label>
              <input
                  id="name_feedback_faq"
                  name="name"
                  type="text"
                  class="bg-white w-full rounded-28px py-15px px-5.5 b-1 b-solid capitalize h-13.5 flex items-center"
                  :placeholder="$t('PLEASE_ENTER_YOUR_NAME')"
                  v-model="infoForm.name"
              />
            </div>
            <div class="space-y-1 pt-2">
              <label
                  for="email_feedback_faq"
                  class="text-black/25 text-10px md:text-13px block pb-2 uppercase"
              >
                {{ $t('ENTER_YOUR_EMAIL_FAQ') }}
              </label>
              <input
                  id="email_feedback_faq"
                  name="email"
                  type="email"
                  required
                  class="bg-white w-full rounded-28px py-15px px-5.5 b-1 b-solid h-13.5 flex items-center"
                  :placeholder="$t('INPUT_EMAIL')"
                  v-model="infoForm.email"
              />
            </div>

            <div class="flex justify-between pt-2 lg:pt-5">
              <label
                  for="feedback_not_good"
                  class="group text-black/60 flex flex-col items-center gap-1 lg:gap-5"
              >
                <div
                    :class="+infoForm.level === 1 ? 'bg-final-03' : ''"
                    class="w-13 h-13 rounded-full flex flex-col items-center justify-center group-hover:bg-final-03 duration-200"
                >
                                    <span
                                        :class="+infoForm.level === 1 ? 'text-primary' : ' text-black'"
                                        class="i-custom-not-good text-2xl xl:text-32px group-hover:text-primary"
                                    ></span>
                </div>
                <p class="text-xs lg:text-sm">{{ $t('NOT_HELPFUL') }}</p>
                <input
                    type="radio"
                    id="feedback_not_good"
                    name="level"
                    value="1"
                    class="hidden"
                    v-model="infoForm.level"
                />
              </label>
              <label
                  for="feedback_good"
                  class="group text-black/60 flex flex-col items-center gap-1 lg:gap-5"
              >
                <div
                    :class="+infoForm.level === 2 ? 'bg-final-03' : ''"
                    class="w-13 h-13 rounded-full flex flex-col items-center justify-center group-hover:bg-final-03 duration-200"
                >
                                    <span
                                        :class="+infoForm.level === 2 ? 'text-primary' : 'text-black'"
                                        class="i-custom-good text-2xl xl:text-32px group-hover:text-primary"
                                    ></span>
                </div>
                <p class="text-xs lg:text-sm">{{ $t('NORMAL') }}</p>
                <input
                    type="radio"
                    id="feedback_good"
                    name="level"
                    value="2"
                    class="hidden"
                    v-model="infoForm.level"
                />
              </label>
              <label
                  for="feedback_very_good"
                  class="group text-black/60 flex flex-col items-center gap-1 lg:gap-5"
              >
                <div
                    :class="+infoForm.level === 3 ? 'bg-final-03' : ''"
                    class="w-13 h-13 rounded-full flex flex-col items-center justify-center group-hover:bg-final-03 duration-200"
                >
                                    <span
                                        :class="+infoForm.level === 3 ? 'text-primary' : 'text-black'"
                                        class="i-custom-very-good text-2xl xl:text-32px group-hover:text-primary"
                                    ></span>
                </div>
                <p class="text-xs lg:text-sm">{{ $t('VERY_HELPFUL') }}</p>
                <input
                    type="radio"
                    id="feedback_very_good"
                    name="level"
                    v-model="infoForm.level"
                    value="3"
                    class="hidden"
                />
              </label>
            </div>

            <div class="space-y-1">
                            <textarea
                                id="memo"
                                name="note"
                                rows="3"
                                cols="50"
                                class="min-w-full max-w-full min-h-52px bg-white w-full rounded-xl py-15px px-5.5 b-1 b-solid mt-2"
                                :placeholder="$t('IMPORT_CONTENT')"
                                v-model="infoForm.note"
                            ></textarea>
            </div>
          </div>
          <div class="px-12.5">
            <button
                class="relative w-full btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm max-h-14 rounded-30px mt-auto py-3.5 lg:py-4.5 flex items-center justify-center h-13.5"
                :class="successSubmit ? '' : 'pointer-events-none'"
            >
              <p v-if="successSubmit">{{ $t('SUBMIT_FEEDBACK') }}</p>
              <div v-else class="absolute loaderForm"></div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'

defineProps({
  closePopup: {
    type: Boolean
  }
})
const emit = defineEmits(['openSuccessPopup'])
const infoForm = ref({
  lang: useRoute().params.langCode,
  name: '',
  level: '',
  email: '',
  note: ''
})
const successSubmit = ref(true)

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await recaptchaInstance?.recaptchaLoaded()

  // get the token, a custom action could be added as argument to the method
  const token = await recaptchaInstance?.executeRecaptcha('login')

  return token
}
const submitFeedbackJs = async (): Promise<void> => {
  successSubmit.value = false
  const token = await recaptcha()
  infoForm.value.name = capitalizeFirstLetterOfWords(infoForm.value.name)
  fetch(`/api/feedback?response=${token}&id=${useRoute().params.id}`, {
    method: 'POST',
    body: JSON.stringify(infoForm.value)
  })
      .then((response) => {
        if (response.status == 200) {
          successSubmit.value = true
          emit('openSuccessPopup', true)
        }
      })
      .catch((error) => {
        console.log('🚀 ~ file: index.vue:179 ~ submitFeedbackJs ~ error:', error)
        successSubmit.value = true
      })
}
</script>

<style scoped></style>
