<template>
  <form @submit.prevent="searchFAQ" class="w-full rounded-xl">
    <div class="relative flex items-center lg:max-w-sm input-search-faq rounded-xl border border-bw-08">
      <input
          name="search_faq"
          type="text"
          class="relative bg-white rounded-xl pl-12 pr-2 py-3.5 w-full text-sm xl:text-base"
          :placeholder="$t('FIND_THE_RIGHT_QUESTION_2')"
          v-model="textSearch"
      />
      <button class="absolute left-3.5 text-primary hover:text-accent-01">
        <div v-if="!loading" class="i-ic:twotone-search w-5 h-5 xl:w-6 xl:h-6 duration-400 text-primary"></div>
        <div v-else class="loaderForm"></div>
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
const loading = ref(false)
const emits = defineEmits(['searchText'])
const textSearch = ref()
const searchFAQ = () => {
  loading.value = true
  // emits('searchText', textSearch.value)
  setTimeout(() => {
    emits('searchText', textSearch.value)
    loading.value = false
  }, 500)
}
watch(
    () => textSearch.value,
    (newValue) => {
      if (newValue == '') {
        loading.value = true

        setTimeout(() => {
          emits('searchText', textSearch.value)
          loading.value = false
        }, 500)
      }
    }
)
</script>

<style scoped>
.bg-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
</style>
