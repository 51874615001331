<template>
  <div class="h-3px bg-bw-08 sticky top-18.5 md:top-19.5 lg:top-21.5 xl:top-22 z-500">
    <div class="h-full bg-primary w-10" :style="{ width: scrollPercentage + '%' }"></div>
  </div>
  <section class="relative bg-white">
    <div class="container mb-10 mt-10 xl:mt-20 lg:mb-20">
      <!-- <GlobalSkeleton :loading="pending" class="w-3/10 h-6 mb-1" /> -->
      <div>
        <global-breadcrumb :breadcrumb="breadcrumb"></global-breadcrumb>
      </div>

      <div class="w-full hidden lg:block h-1px bg-bw-08 mt-5 mb-5"></div>
      <div class="flex flex-col lg:flex-row h-full xl:mt-17">
        <div class="mb-1 lg:mb-0 mt-0 w-full lg:w-4/12 h-full lg:sticky lg:top-30">
          <div class="lg:hidden">
            <div
                @click="openSidebar = !openSidebar"
                class="flex justify-between gap-2 cursor-pointer group py-3"
            >
              <h1
                  class="text-2xl font-[550] text-primary lg:text-bw-03 lg:mt-6 group-hover:text-primary/90"
              >
                {{
                  !modelCategory?.category?.parent
                      ? renderData?.model?.title
                      : modelCategory?.category?.title
                }}
              </h1>
              <p
                  :class="openSideContentbar ? 'rotate-180' : 'rotate-0'"
                  class="flex-none i-ic:twotone-keyboard-arrow-down text-bw-04 text-xl duration-300 transform"
              ></p>
            </div>
            <div class="item-parent" :class="openSidebar ? 'activeList mt-3' : ''">
              <div class="b-1 lg:b-0 lg:shadow-none lg:rounded-none rounded-lg shadow-lg b-bw-08">
                <div class="px-3 mt-4 mb-2 lg:hidden">
                  <search-faq @searchText="getDataSearch"/>
                </div>
                <sidebar :category="listRenderSidebarDefault" @searchText="getDataSearch"/>
              </div>
            </div>
          </div>
          <div class="mb-5 hidden lg:block">
            <sidebar :category="listRenderSidebarDefault" @searchText="getDataSearch"/>
          </div>
        </div>
        <div class="lg:ml-4 xl:ml-10 w-full lg:w-6/12 mr-8 xl:mr-10 mt-2 md:mt-3">
          <!-- <GlobalSkeleton :loading="pending" class="w-3/10 h-5" /> -->

          <div v-if="modelCategory?.category?.date_created" class="flex items-center gap-1 text-[#9C9C9C]/90">
            <span class="i-ic:outline-calendar-today w-3.5 h-3.5"></span>
            <span class="text-xs xl:text-sm font-semibold">{{
                modelCategory?.category?.date_created
              }}</span>
          </div>

          <div
              v-show="showTableOfContents"
              class="mt-2 lg:mt-0 relative inset-0 bg-white py-2 lg:py-0 lg:pb-5"
          >
            <div class="lg:hidden b-1 b-solid rounded px-4 py-2 bg-bw-04/10">
              <div
                  @click="showTableOfContensMobile = !showTableOfContensMobile"
                  class="flex items-center justify-between w-full gap-2 cursor-pointer"
              >
                <h2 class="flex-1 text-black font-[550] text-lg">{{ $t('MAIN_CONTENT') }}</h2>
                <button
                    class="text-bw-15 w-7 h-7 flex flex-col items-center justify-center b-1 b-solid b-bw-04/70 rounded hover:bg-bw-04/20 duration-200"
                >
                  <span class="i-ic:twotone-format-list-bulleted text-lg"></span>
                </button>
              </div>
              <div class="item-parent" :class="showTableOfContensMobile ? 'activeList my-3 xl:my-5' : ''">
                <div>
                  <div class="h-1px w-full bg-bw-20"></div>
                  <div>
                    <tableOfContent @listHeading="checkHeading"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- <GlobalSkeleton :loading="pending" class="w-7/10 h-8 lg:mt-6" /> -->
            <h1
                class="hidden lg:block leading-7 xl:leading-10 text-xl lg:text-2xl xl:text-30px font-[550] text-primary lg:text-bw-03 lg:mt-6 line-clamp-3"
            >
              {{
                !modelCategory?.category?.parent
                    ? renderData?.model?.title
                    : modelCategory?.category?.title
              }}
            </h1>

            <!-- <div
                    v-show="showToc && showTableOfContents"
                    class="absolute top-full left-0 w-7/10 bg-white py-6.7 px-5 b-1 b-black/10 overflow-auto max-h-85vh custom-scrollbar"
                >
                    <p class="text-sm font-semibold text-primary uppercase">
                        {{ $t('TABLE_OF_CONTENTS') }}
                    </p>
                    <div class="h-1px w-full bg-bw-20 my-5"></div>
                    <div>
                        <tableOfContent @list-heading="checkHeading" />
                    </div>
                </div> -->
          </div>

          <div class="w-full h-1px bg-bw-08 mb-5 lg:mb-15 hidden"></div>

          <content :content="renderData?.model?.content"/>
          <!-- <GlobalSkeleton :loading="pending" class="w-full h-5 mt-5" /> -->
          <p v-if="checkShowFeedback" class="text-black font-semibold mt-5 text-sm xl:text-base">
            {{ $t('WAS_THIS_ARTICLE_HELPFUL') }}
            <button
                @click="isOpen = true"
                class="text-primary cursor-pointer font-semibol text-sm xl:text-base"
            >
              {{ $t('FEEDBACK_NOW') }}
            </button>
          </p>
          <div class="h-1px w-full mt-10 mb-2 bg-bw-08"></div>
          <div
              :class="showNextPage && !showPreviousPage ? 'justify-end' : ' justify-between'"
              class="flex font-semibold"
          >
            <button
                v-if="showPreviousPage"
                @click="previousPage"
                class="text-primary flex items-center gap-1"
            >
              <span class="i-ic:sharp-keyboard-arrow-left mt-1px"></span>
              <span>{{ $t('PREV_PAGE') }}</span>
            </button>
            <button v-if="showNextPage" @click="nextPage" class="text-primary flex items-center gap-1">
              <span>{{ $t('NEXT_PAGE') }}</span>

              <span class="i-ic:sharp-keyboard-arrow-right mt-1px"></span>
            </button>
          </div>
        </div>
        <div
            data-aos="zoom-in"
            data-aos-once="true"
            class="w-full lg:w-3/12 static lg:sticky lg:top-33 h-full mb-10 hidden lg:block mt-3"
        >
          <div
              v-show="showTableOfContents"
              class="py-4 xl:py-6.5 px-3.5 xl:px-5 rounded-xl b-1 b-black/10 overflow-auto max-h-85vh custom-scrollbar wrapperSidebar"
          >
            <p class="text-xs xl:text-sm font-[550] text-black uppercase">
              {{ $t('TABLE_OF_CONTENTS') }}
            </p>
            <div class="h-1px w-full bg-bw-20 my-3 xl:my-5"></div>
            <div>
              <tableOfContent
                  class="w-full h-full overflow-auto lg:max-h-60vh xl:max-h-65vh custom-scrollbar wrapperSidebar"
                  @list-heading="checkHeading"
              />
            </div>
          </div>
          <div class="mt-4 space-y-2.5">
            <button
                @click="printContent"
                class="hover:bg-primary hover:text-final-02 w-9 h-9 rounded-full bg-final-02 text-primary flex flex-col items-center justify-center duration-200"
            >
              <span class="i-ic:baseline-local-printshop text-lg"></span>
            </button>
            <button
                @click="handleCopyLink"
                class="hover:bg-primary hover:text-white w-9 h-9 rounded-full bg-final-02 text-primary flex flex-col items-center justify-center duration-200"
            >
              <span class="w-4 h-4 i-custom-share"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Popup v-if="isOpen" @closePopup="closePopup" @openSuccessPopup="handleSuccess"/>
    <PopupSuccess v-model:visible="openSuccess"/>
    <GlobalPopupCopySuccess v-model:isOpen="copySuccess"/>
  </section>
</template>

<script setup lang="ts">
import TableOfContent from './components/TableOfContent.vue'
import Content from './components/Content.vue'
import Sidebar from './components/Sidebar.vue'
import Popup from './components/__Popup.vue'
import PopupSuccess from './components/__PopupSuccess.vue'
import {useFaq} from '../../../composables/faq/index'
import {onClickOutside} from '@vueuse/core'
import {vOnClickOutside} from '@vueuse/components'
import SearchFaq from './components/Search.vue'
import {encrypt, decrypt} from '../../../../utils/encode.utils'
import {useI18n} from 'vue-i18n'
import {useGetLangCurrent} from '../../../stores/useLanguageMenu'

const isOpen = ref(false)
const openSidebar = ref(false)
const openSuccess = ref(false)
const {getFaqById, getFaqsByCategoryId} = useFaq()
const renderData: any = await getFaqById(useRoute().params.id, useRoute().params.langCode, useSeoFields('faqs'))
const modelCategory: any = await getFaqsByCategoryId(renderData.model?.category, useRoute().params.langCode)
const listRenderSidebarDefault = ref(renderData.category)
const router = useRouter()
const showToc = ref(false)
const {t} = useI18n()
const closeToc = () => {
  showToc.value = false
}

const pageHeight = ref(0)
const scrollPosition = ref(0)

const updatePageHeight = () => {
  pageHeight.value = document.documentElement.scrollHeight - window.innerHeight
}

const updateScrollPosition = () => {
  scrollPosition.value = window.scrollY
}

const updateValues = () => {
  requestAnimationFrame(() => {
    updatePageHeight()
    updateScrollPosition()
  })
}
const scrollPercentage = computed(() => {
  return Math.round((scrollPosition.value / pageHeight.value) * 100)
})
const showTableOfContents = ref(false)
const showTableOfContensMobile = ref(false)
const checkHeading = (data: any) => {
  if (data && data.length === 0) {
    showTableOfContents.value = false
  } else {
    showTableOfContents.value = true
  }
}
const closePopup = () => {
  isOpen.value = false
}
const copySuccess = ref(false)
const checkShowFeedback = ref(false)
const printContent = () => {
  printJS({
    printable: 'content-faq',
    type: 'html'
  })
}

const searchInCategory = (categories: any[], searchText: string) => {
  const searchKeywords = searchText.toLowerCase().split(/\s+/)
  const filteredCategories: any[] = []

  categories.forEach((category: any) => {
    const filteredCategory: any = {...category}
    const matchingFaqs: any[] = []
    if (category.title && searchKeywords.every((keyword) => category.title.toLowerCase().includes(keyword))) {
      filteredCategories.push(filteredCategory)
    }

    if (category.childs && category.childs.length > 0) {
      const childItems = searchInCategory(category.childs, searchText)
      if (childItems.length > 0) {
        filteredCategory.childs = childItems
        filteredCategories.push(filteredCategory)
      } else {
        filteredCategory.faqs.forEach((child: any) => {
          if (child.title && searchKeywords.every((keyword) => child.title.toLowerCase().includes(keyword))) {
            filteredCategory.childs = []
          }
        })
      }
    }

    if (category.faqs && category.faqs.length > 0) {
      category.faqs.forEach((faq: any) => {
        if (faq.title && searchKeywords.every((keyword) => faq.title.toLowerCase().includes(keyword))) {
          matchingFaqs.push(faq)
        }
      })

      if (matchingFaqs.length > 0) {
        filteredCategory.faqs = matchingFaqs
        filteredCategories.push(filteredCategory)
      } else {
        filteredCategory.childs.forEach((child: any) => {
          if (child.title && searchKeywords.every((keyword) => child.title.toLowerCase().includes(keyword))) {
            filteredCategory.faqs = []
          }
          if (child.faqs && child.faqs.length > 0) {
            child.faqs.forEach((faq: any) => {
              if (
                  faq.title &&
                  searchKeywords.every((keyword) => faq.title.toLowerCase().includes(keyword))
              ) {
                filteredCategory.faqs = []
              }
            })
          }
        })
      }
    }
  })

  return filteredCategories
}

const getDataSearch = (text: any) => {
  if (!renderData) {
    return
  }
  if (!text) {
    listRenderSidebarDefault.value = renderData.category
    return
  }
  const filteredCategories = searchInCategory(renderData.category, text)

  listRenderSidebarDefault.value = filteredCategories
}

const findParentCategory = computed(() => {
  if (modelCategory.category?.parent) {
    const data = renderData.category.find((item: any) => item.id == modelCategory.category.parent)

    return data
  } else {
    return modelCategory.category
  }
})

const breadcrumb = ref([
  {
    title: t('FAQ_INDEX'),
    url: t('FAQ_INDEX_URL')
  },
  {
    title: findParentCategory.value?.title,
    url: findParentCategory.value?.url
  }
  // {
  //     title: !modelCategory.category.parent ? '' : modelCategory.category?.title,
  //     url: renderData.model?.url
  // }
])
const copyToClipboard = (url: any) => {
  var sampleTextarea = document.createElement('textarea')
  document.body.appendChild(sampleTextarea)
  sampleTextarea.value = url //save main text in it
  sampleTextarea.select() //select textarea contenrs
  document.execCommand('copy')
  document.body.removeChild(sampleTextarea)
}
const handleCopyLink = () => {
  copyToClipboard(window.location.href)
  copySuccess.value = true
  setTimeout(() => {
    copySuccess.value = false
  }, 5000)
}

const faqsInCategory = modelCategory.category?.faqs?.filter((faq: any) => {
  return !faq.url.includes('null')
})
const currentItemIndex = faqsInCategory.findIndex((item: any) => +item.id === +useRoute().params.id)
const nextPage = () => {
  router.push(faqsInCategory[currentItemIndex + 1].url)
}
const previousPage = () => {
  router.push(faqsInCategory[currentItemIndex - 1].url)
}
const showNextPage = ref(currentItemIndex < faqsInCategory.length - 1 ? true : false)
const showPreviousPage = ref(currentItemIndex > 0)
let checkFeedback = ref()
try {
  checkFeedback.value = decrypt(`${useCookie('_FORM').value}`)
  if (checkFeedback.value && !checkFeedback.value.faqs_feedback) {
    checkFeedback.value = {
      faqs_feedback: []
    }
  }
} catch (error) {
  checkFeedback.value = {
    faqs_feedback: []
  }
}
const handleSuccess = (status: boolean) => {
  if (status) {
    checkShowFeedback.value = false
    isOpen.value = false
    openSuccess.value = status
  }
}
// const lang = useRoute().params.lang

useDirectusCollectionSeo('faqs', {
  ...renderData?.model,
  seo_detail: {
    ...renderData?.model?.seo_detail
  }
})

const store = useGetLangCurrent()
onMounted(() => {
  updateValues()
  window.addEventListener('scroll', updateValues)
  window.addEventListener('resize', updateValues)
  document.querySelector('html')?.classList.add('scroll-smooth')

  store.lang_switch = renderData?.lang_switch
  if (checkFeedback.value?.faqs_feedback && checkFeedback.value?.faqs_feedback.includes(+useRoute().params.id)) {
    checkShowFeedback.value = false
  } else {
    checkShowFeedback.value = true
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', updateValues)
  window.removeEventListener('resize', updateValues)
  document.querySelector('html')?.classList.remove('scroll-smooth')
})
</script>
<style scoped>
.main-popup-popupCopySucces > .box-popup {
  max-width: 300px;
  border-radius: 20px;
  bottom: 10px;
  padding: 0px 0px;
  @apply shadow-lg !py-0;
}

.main-popup-popupCopySucces > .box-popup > div {
  @apply py-4 px-6;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.wrapperSidebar:hover {
  &::-webkit-scrollbar-thumb {
    background: #d0d0d0;
  }

  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: #f6f6f6; /* Optional: just make scrollbar invisible */
  }
}
</style>
