<template>
  <TableOfContentToc content-selector="#content-faq" @list-heading="checkHeading"/>
</template>

<script setup lang="ts">
defineProps({
  loading: {
    type: Boolean
  }
})
const emit = defineEmits(['listHeading'])
const checkHeading = (data: any) => {
  emit('listHeading', data)
}
</script>

<style></style>
