<template>
  <v-popup overlay closeOverlay onOpenLoadPage keyPopup="popupResultFeedback" :closeable="false">
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                @click="$emit('update:visible', false)"
            ></span>
      <div class="max-w-74 mx-auto">
        <h5 class="text-32px font-[550] leading-8 text-center text-black mb-3">{{ $t('SUBMIT_FEEDBACK') }}</h5>
        <h5 class="text-32px font-[550] leading-8 text-primary mb-3 text-center">{{ $t('SUCCESS') }}</h5>
      </div>
    </template>
    <div class="flex justify-center pt-3 pb-7.5">
      <div>
        <p class="max-w-87 text-center text-black/60">
          {{ $t('CONTENT_FEEDBACK_SUCCESS') }}
        </p>
        <div class="w-50 aspect-40/30 mx-auto">
          <img
              class="w-full h-full object-contain animationSway"
              src="/images/brand-iz4-3.png"
              alt="masgot"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex gap-2.5">
        <button-primary
            :title="$t('BACK')"
            class="flex-1 rounded-full text-13px font-semibold"
            padding="py-4 px-5"
            @click="$emit('update:visible', false)"
        >
        </button-primary>
      </div>
    </template>
  </v-popup>
</template>
<script lang="ts" setup>
const {t} = useI18n()

// const handleContinue = () => {
//     emits('update:visible', false)
// }
</script>
<style>
.box-content-popup-popupResultFeedback {
  @apply lg:!w-400px;
}
</style>
